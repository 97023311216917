<template>
  <div class="empty-state">
    <div v-html="message" />
    <slot />
  </div>
</template>

<i18n>
{
"en": {
"message": "It's empty"
},
"ja": {
"message": "空です"
}
}
</i18n>

<script>
export default {
  name: 'EmptyState',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    messageT() {
      return this.message ? this.message : this.$t('message');
    },
  },
};
</script>

<style lang="scss">
.empty-state {
  padding: $bleed * 5;
  border: 1px dashed $border;
  text-align: center;
  font-weight: bold;
  color: $border;
}
</style>
