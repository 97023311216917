<template>
  <div class="page">
    <!--page title-->
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="is-3 title">
            {{ $t('Data Extraction') }}
          </h2>
        </div>
      </div>
    </section>
    <!--search bar-->
    <section class="has-background-secondary py-4 px-5">
      <div class="container">
        <SearchForm
          :loading="isFetching"
          @search="fetchData"
        />
      </div>
    </section>
    <!--list-->
    <div class="section py-5">
      <div class="container">
        <div v-if="!canFetch">
          {{ $t('no-search-params') }}
        </div>
        <div v-if="list">
          <template v-if="pagination.total">
            <div class="has-text-weight-bold is-size-7 has-text-black mb-3">
              {{ $t('showing-properties', {total:pagination.total}) }}
            </div>
            <div>
              <Property
                v-for="property in list"
                :key="property.propertyCode"
                v-bind="property"
              />
            </div>
            <div class="is-flex is-align-items-center mt-6">
              <div class="is-flex-grow-1 is-flex-shrink-1">
                <a class="is-size-7" @click.prevent="scrollToTop">{{ $t('Back to top') }}</a>
              </div>
              <a-pagination
                v-model:current="pagination.current"
                :total="pagination.total"
                :show-total="(total, range) => $t('show-total',{
                  range0: range[0],
                  range1: range[1],
                  total: total
                })"
                :page-size="pagination.pageSize"
                @change="onPaginationChange"
              />
            </div>
          </template>
          <EmptyState v-else message="No results" />
        </div>
      </div>
    </div>
  </div>
</template>
<i18n src="./locales.json"></i18n>
<script>
import animateScrollTo from 'animated-scroll-to';
import SearchForm from '@/views/simulations/components/extractions/SearchForm';
import pagination from '@/components/mixins/pagination';
import Property from '@/views/simulations/components/extractions/Property';
import { getLocaleWithCountry } from '@/utils/locale';
import EmptyState from '@/components/EmptyState';

export default {
  name: 'Extractions',
  components: { EmptyState, Property, SearchForm },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      inProgress: false,
    };
  },
  computed: {
    canFetch() {
      return this.$route.query.regionId
          && this.$route.query.currency
          && this.$route.query.adultCount
          && this.$route.query.roomCount
          && this.$route.query.checkin;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.canFetch) {
        return;
      }

      this.list = undefined;
      this.isFetching = true;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      try {
        const { data, inProgress, pagination } = await this.$store.dispatch('extractions/list', {
          ...this.$route.query,
          locale: getLocaleWithCountry(this.$i18n.locale),
        });
        this.list = data;
        this.inProgress = inProgress;
        this.setPagination(pagination);
        this.isFetching = false;
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
        if (inProgress) {
          this.fetchData();
        }
      } catch (err) {
        this.isFetching = false;
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    scrollToTop() {
      animateScrollTo(0, {
        elementToScroll: document.querySelector('#body'),
      });
    },
    async onPaginationChange() {
      await this.preparePagination({
        ...this.pagination,
      });
      await this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-pagination {
  margin-top: 0;
}
::v-deep .ant-pagination-total-text {
  padding-right: 10px;
}
.page {
  background-color: #F5F5F5;
  min-height: 100vh;
}
</style>
