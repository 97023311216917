<template>
  <div class="property is-flex-tablet is-align-items-center">
    <div class="is-flex-grow-1 is-flex-shrink-1">
      <div class="is-flex-tablet">
        <div class="has-text-grey mr-2">
          {{ propertyCode }}
        </div>
        <div class="has-text-weight-bold mr-2">
          {{ name }}
        </div>
        <div>
          <StarRatings :ratings="starRating" />
        </div>
      </div>
      <div class="is-flex-tablet mb-1">
        <div class="has-text-grey mr-2">
          {{ category }}
        </div>
        <PropertyLocation v-bind="location" />
      </div>
      <TrustYouReview class="mb-1" v-bind="trustYou" />
      <div class="mb-1-mobile-only">
        <span class="mr-1">{{ $t('from') }}</span>
        <strong>{{ $filters.currencyObj(lowestPrice) }}</strong>
      </div>
    </div>
    <div class="is-flex-grow-0 is-flex-shrink-0">
      <a-button class="ant-btn ant-btn-primary ant-btn-lg" @click="handleMoreInfo">
        {{ $t('More Info') }}
      </a-button>
    </div>
  </div>
</template>

<i18n src="../../locales.json"></i18n>

<script>
import StarRatings from '@/components/StarRatings';
import PropertyLocation from '@/components/PropertyLocation';
import TrustYouReview from '@/components/TrustYouReview';
import propertyMixin from '@/views/simulations/mixins/extraction-property';

export default {
  name: 'Property',
  components: { TrustYouReview, PropertyLocation, StarRatings },
  mixins: [propertyMixin],
  props: {
    propertyCode: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    starRating: {
      type: Number,
      default: 0,
    },
    location: {
      type: Object,
      default: null,
    },
    trustYou: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    policy: {
      type: String,
      default: '',
    },
    facilities: {
      type: Array,
      default: () => [],
    },
    covidSafety: {
      type: String,
      default: '',
    },
    reviews: {
      type: Object,
      default: null,
    },
    category: {
      type: String,
      default: '',
    },
    regionIds: {
      type: Array,
      default: () => [],
    },
    isPropertyOpened: {
      type: [Boolean, null],
      default: null,
    },
    checkinTime: {
      type: String,
      default: '',
    },
    checkoutTime: {
      type: String,
      default: '',
    },
    website: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    fax: {
      type: String,
      default: '',
    },
    rate: {
      type: Object,
      default: null,
    },
    rooms: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lowestPrice() {
      return {
        value: this.rate?.lowest,
        currency: this.rate?.currency,
      };
    },
  },
  methods: {
    handleMoreInfo() {
      this.viewProperty(this.propertyCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.property {
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px;
}

.mb-1-mobile-only {
  margin-bottom: 0.25rem;
}

@include tablet {
  .mb-1-mobile-only {
    margin-bottom: 0;
  }
}
</style>
