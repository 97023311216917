{
  "en": {
    "Data Extraction": "Data Extraction",
    "Select destination": "Select destination",
    "No result": "No results found.",
    "Search":"Search",
    "View Property":"View Property",
    "no-search-params": "Please input the search params to start.",
    "show-total": "Showing Results {range0} – {range1} of {total}",
    "showing-properties":"Showing {total} properties",
    "Property Information": "Property Information",
    "Room Information": "Room Information",
    "Price": "Price",
    "Guest Review": "Guest Review",
    "Website": "Website",
    "Email": "Email",
    "Phone": "Phone",
    "Fax": "Fax",
    "Check in": "Check in",
    "Check out": "Check out",
    "Airport": "Airport",
    "Chain": "Chain",
    "Facilities": "Facilities",
    "Status": "Status",
    "Open": "Open",
    "Close": "Close",
    "Property Description":"Property Description",
    "Check In/Check Out Policy": "Check In/Check Out Policy",
    "More Info": "More Info",
    "from": "from",
    "Filter":"Filter",
    "Save":"Save",
    "Cancel":"Cancel",
    "No rating": "No rating",
    "CLEAR": "CLEAR",
    "Price per night":"Price per night",
    "Reviews":"Reviews",
    "Star rating":"Star rating",
    "Excellent":"Excellent",
    "Very Good":"Very Good",
    "Good":"Good",
    "Fair":"Fair",
    "Poor":"Poor",
    "No Reviews": "No Reviews",
    "children": "children",
    "age-of-child": "Age of child",
    "age": "age",
    "apply": "APPLY",
    "rooms": "rooms",
    "1-room": "1 room",
    "adults": "adults",
    "1-adult": "1 adult",
    "children-a": "children",
    "0-children": ", 0 children",
    "title": "Occupancy",
    "children-count-label": "{n} child | {n} child | {n} children",
    "room-count-label": "{n} room | {n} room | {n} rooms",
    "adult-count-label": "{n} adult | {n} adult | {n} adults",
    "beds": "beds",
    "size": "size",
    "Total no of Booking": "Total no of Booking",
    "Export to csv including detail info": "Export to csv including detail info",
    "Export to csv excluding detail info": "Export to csv excluding detail info",
    "Confirm": "Confirm"
  },
  "ja": {
    "Data Extraction": "Data Extraction",
    "Select destination": "行き先を選択してください",
    "No result": "結果は得られなかった。",
    "Search":"検索",
    "View Property":"View Property",
    "no-search-params": "Please input the search params to start.",
    "show-total": "検索結果を表示 {range0} – {range1} {total}",
    "showing-properties":"Showing {total} properties",
    "Property Information": "Property Information",
    "Room Information": "Room Information",
    "Price": "価格",
    "Guest Review": "Guest Review",
    "Website": "Website",
    "Email": "Email",
    "Phone": "Phone",
    "Fax": "Fax",
    "Check in": "チェックイン日",
    "Check out": "チェックアウト日",
    "Airport": "Airport",
    "Chain": "Chain",
    "Facilities": "宿泊施設設備",
    "Status": "Status",
    "Open": "Open",
    "Close": "Close",
    "Property Description":"Property Description",
    "Check In/Check Out Policy": "Check In/Check Out Policy",
    "More Info": "More Info",
    "from": "from",
    "Filter":"フィルタ",
    "Save":"保存",
    "Cancel":"取り消し",
    "No rating": "点数なし",
    "CLEAR": "検索条件をクリア",
    "Price per night":"宿泊日平均",
    "Reviews":"口コミ",
    "Star rating":"ホテルランク",
    "Excellent":"すばらしい",
    "Very Good":"大変良い",
    "Good":"良い",
    "Fair":"普通",
    "Poor":"悪い",
    "No Reviews": "口コミなし",
    "children": "子供",
    "age-of-child": "年齢",
    "age": "年齢",
    "apply": "適用する",
    "rooms": "部屋",
    "1-room": "1部屋",
    "adults": "大人",
    "1-adult": "大人1名",
    "children-a": "子供",
    "0-children": ", 子供0名",
    "title": "宿泊可能人数",
    "children-count-label": "子供{n}人 | 子供{n}人 | 子供{n}人",
    "room-count-label": "{n}部屋 | {n}部屋 | {n}部屋",
    "adult-count-label": "大人{n}人 | 大人{n}人 | 大人{n}人",
    "beds": "beds",
    "size": "size",
    "Total no of Booking": "Total no of Booking",
    "Export to csv including detail info": "Export to csv including detail info",
    "Export to csv excluding detail info": "Export to csv excluding detail info",
    "Confirm": "Confirm"
  }
}
